<template>
  <div class="body">
    <div class="company_img">
      <img :src="bannerimg" />
    </div>
    <div class="company_info">
      <div class="product_title">
        <div class="product_tit">
          <div class="tit1">公司简介</div>
          <div class="tit2"></div>
        </div>
      </div>
      <div class="company_con" v-html="content">

      </div>
      <!-- <div class="map">
        <img :src="addressimg" alt="">
      </div> -->
      <div class="company-map">
        <iframe width="420" height="350" frameborder='0' scrolling='no' marginheight='0' marginwidth='0' src="https://surl.amap.com/dgDW60r40Aa"></iframe>
      </div>

    </div>
  </div>
</template>

<script>
import { getbannerdetail } from "@/http/api/user.js";
export default {
  data() {
    return {
      bannerimg: "",
      content: "",
      addressimg: "",
    };
  },
  mounted() {
    this.getcompany();
  },
  created() {},
  methods: {
    //获取公司简介
    async getcompany() {
      let params = {
        barId: 2,
      };
      const data = await getbannerdetail(params);
      this.bannerimg = "http://36.152.65.166:10008" + data.data.image;
      this.content = data.data.children[0].content;
      this.addressimg =
        "http://36.152.65.166:10008" + data.data.children[0].image;
      // console.log(data.data);
    },
  },
};
</script>

<style >
</style>
<style lang="scss" scoped>
.body {
  height: 5.5rem;
  .company-map {
    border-top: 1px solid #d9d9d9;
    width: 90%;
    display: flex;
    padding: 10px;
    justify-content: center;
  }
  .product_title {
    width: 7.5rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .product_tit {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 0.6rem;
      .tit1 {
        font-size: 0.3rem;
        font-weight: bold;
        margin-bottom: 0.13rem;
      }
      .tit2 {
        width: 0.6rem;
        height: 0.04rem;
        background-color: #0088ea;
      }
    }
  }
  .company_img {
    width: 100%;
    height: 2.44rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .company_info {
    .company_con {
      width: 6.5rem;
      margin: 0 auto;
      ::v-deep {
        line-height: 0.4rem;
        text-indent: 2em;
        font-size: 0.2rem;
      }
    }
    .map {
      border-top: 0.01rem solid #d9d9d9;
      width: 7.1rem;
      height: 3.19rem;
      margin: 0.36rem auto;
      img {
        width: 100%;
      }
    }
  }
}
</style>